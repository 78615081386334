<template>
  <v-row>
    <v-col cols="12" md="12" class="my-font-class">
      <VueHtml2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="invoice"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <base-card id="invoice">
            <div class="mb-10">
              <div
                style="
                  width: 80%;
                  margin-right: auto !important;
                  margin-left: auto !important;
                "
              >
                <v-row>
                  <v-col cols="12">
                    <div class="">
                      <div
                        class=""
                        style="
                          background: #f5f5f5;
                          text-align: center;
                          padding: 16px 0;
                          margin-top: 30px;
                          width: 100%;
                        "
                      >
                        <img
                          class="mr-2"
                          src="@/assets/logo.png"
                          alt=""
                          srcset=""
                          style="width: 150px"
                        />
                      </div>
                      <v-divider dark class="my-12"></v-divider>
                    </div>

                    <div
                      class=""
                      style="padding-bottom: 20px !important; width:100%; margin-left: 13px;}"
                    >
                      <tr>
                        <td style="width: 75%">
                          <h5>{{ company.name }}</h5>
                          <p>
                            <strong>Address:</strong> {{ company.address }}
                            <br />
                            <strong>Attendant:</strong> {{ loggedInUser }}
                          </p>
                        </td>
                      </tr>
                    </div>

                    <div
                      class=""
                      style="padding-top: 20px !important; padding-bottom: 20px !important; width:100%; margin-left: 13px;}"
                    >
                      <tr>
                        <td style="width: 75%">
                          <h6 style="font-weight: 600">
                            #{{ getSale.invoiceId }}
                          </h6>
                          <h6 style="font-weight: 600">
                            Item purchased on: <br />{{
                              new Date(getSale.createdAt).toLocaleString()
                            }}
                          </h6>
                        </td>
                        <td style="width: 20% !important">
                          <h6 style="text-align: right !important">
                            {{ new Date().toLocaleString() }}
                          </h6>
                        </td>
                      </tr>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-card-text style="letter-spacing: 0.0071428571em">
              <div
                style="
                  width: 80%;
                  margin-right: auto !important;
                  margin-left: auto !important;
                "
              >
                <div
                  style="
                    background-color: #ffffff;
                    color: rgba(0, 0, 0, 0.87);
                    line-height: 1.5;
                    max-width: 100%;
                    border-radius: 4px;
                    overflow-x: auto;
                    overflow-y: hidden;
                  "
                >
                  <table
                    style="width: 100%; border-spacing: 0; line-height: 1.5"
                  >
                    <thead>
                      <tr style="border-bottom: thin solid rgba(0, 0, 0, 0.12)">
                        <th
                          style="
                            font-size: 0.875rem;
                            color: rgba(0, 0, 0, 0.6);
                            height: 48px;
                            padding: 0 16px;
                            text-align: left !important;
                            padding-left: 0px !important;
                            user-select: none;
                            border-bottom: thin solid rgba(0, 0, 0, 0.12);
                            transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          "
                        >
                          Items
                        </th>
                        <th
                          style="
                            font-size: 0.875rem;
                            border-bottom: thin solid rgba(0, 0, 0, 0.12);
                            color: rgba(0, 0, 0, 0.6);
                            height: 48px;
                            user-select: none;
                            padding: 0 16px;
                            transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                            text-align: right !important;
                            padding-right: 0px !important;
                          "
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="hover:bg-transparent"
                        v-for="item in getSale.stockHistories"
                        :key="item.finalQuantity"
                      >
                        <td
                          style="
                            font-size: 1rem;
                            font-weight: 700;
                            border-bottom: 2px solid rgba(0, 0, 0, 0.12);
                            height: 48px;
                            padding: 0 16px;
                            transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                            padding-left: 0px !important;
                          "
                        >
                          {{ item.purchasedQuantity }} of
                          {{ item.stock.product.name }}
                        </td>
                        <td
                          style="
                            font-size: 1rem;
                            font-weight: 700;
                            border-bottom: 2px solid rgba(0, 0, 0, 0.12);
                            height: 48px;
                            padding: 0 16px;
                            transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                            text-align: right !important;
                            padding-right: 0px !important;
                          "
                        >
                          {{ currency
                          }}{{
                            item.purchasedQuantity * item.stock.product.price
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <table style="width: 100%; border-spacing: 0; line-height: 1.5">
                  <thead>
                    <tr style="border-bottom: thin solid rgba(0, 0, 0, 0.12)">
                      <th
                        style="
                          font-size: 0.875rem;
                          color: rgba(0, 0, 0, 0.6);
                          height: 48px;
                          padding: 0 16px;
                          text-align: left !important;
                          padding-left: 0px !important;
                          user-select: none;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                        "
                      ></th>
                      <th
                        style="
                          font-size: 0.875rem;
                          color: rgba(0, 0, 0, 0.6);
                          height: 48px;
                          user-select: none;
                          padding: 0 16px;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          text-align: right !important;
                          padding-right: 0px !important;
                        "
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="hover:bg-transparent">
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 600;
                          padding: 0 16px;
                          text-align: right !important;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          padding-left: 0px !important;
                        "
                      >
                        Calculated Total
                      </td>
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 600;
                          padding: 0 16px;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          text-align: right !important;
                          padding-right: 0px !important;
                        "
                      >
                        {{ currency }}{{ getSale.totalPrice }}
                      </td>
                    </tr>
                    <tr class="hover:bg-transparent">
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 600;
                          padding: 0 16px;
                          text-align: right !important;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          padding-left: 0px !important;
                        "
                      >
                        Discount
                      </td>
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 600;
                          padding: 0 16px;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          text-align: right !important;
                          padding-right: 0px !important;
                        "
                      >
                        {{ getSale.discount }}
                      </td>
                    </tr>
                    <tr class="hover:bg-transparent">
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 600;
                          padding: 0 16px;
                          text-align: right !important;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          padding-left: 0px !important;
                        "
                      >
                        Tax
                      </td>
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 600;
                          padding: 0 16px;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          text-align: right !important;
                          padding-right: 0px !important;
                        "
                      >
                        {{ getSale.tax }}
                      </td>
                    </tr>
                    <tr class="hover:bg-transparent">
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 700;
                          padding: 0 16px;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          padding-left: 0px !important;
                        "
                      ></td>
                      <td
                        style="
                          font-size: 1.5rem;
                          font-weight: 900;
                          padding: 0 16px;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          text-align: right !important;
                          padding-right: 0px !important;
                        "
                      >
                        {{ currency
                        }}{{
                          getSale.totalPrice + getSale.discount + getSale.tax
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-card-text>

            <div style="padding-top: 4rem; margin-bottom: 1.5rem; width: 100%">
              <div style="text-align: center">
                <button
                  style="
                    background-color: #3869d4;
                    border-top: 10px solid #3869d4;
                    border-right: 18px solid #3869d4;
                    border-bottom: 10px solid #3869d4;
                    border-left: 18px solid #3869d4;
                    display: inline-block;
                    margin-top: 10px;
                    margin-bottom: 15px;
                    color: #fff;
                    text-decoration: none;
                    border-radius: 3px;
                    box-sizing: border-box;
                  "
                >
                  Paid with: {{ getSale.paymentType }}
                </button>
              </div>
            </div>
          </base-card>
        </section>
      </VueHtml2pdf>
      <div class="bg-off-white py-4">
        <div class="mx-auto w-4/5">
          <div class="flex justify-between flex-wrap">
            <v-btn color="primary" outlined @click="sendMailDialog">Send</v-btn>

            <v-btn @click="printInvoice" color="primary" class="text-white"
              >Print Invoice</v-btn
            >
          </div>
        </div>
      </div>
      <!-- <v-btn
        class="m-2"
        @click="printInvoice"
        style="float: right"
        color="primary"
      >
        Print
      </v-btn>
      <v-btn
        class="m-2"
        @click="sendMailDialog"
        style="float: right"
        color="primary"
        :loading="loading"
        :disabled="loading"
      >
        Send
      </v-btn> -->
    </v-col>
    <div class="text-center">
      <v-dialog v-model="sendDialog" width="500">
        <base-card>
          <v-card-title>Send Invoice</v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-list flat>
                <v-text-field
                  filled
                  color="info"
                  v-model="email"
                  :rules="emailRules"
                  type="email"
                  placeholder="Enter Email"
                ></v-text-field>
              </v-list>
            </v-card-text>
            <v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="mt-n10"
                  color="primary"
                  @click="sendInvoice"
                  :loading="loadingE"
                  :disabled="loadingE"
                  >Send Invoice</v-btn
                >
              </v-card-actions>
            </v-container>
          </v-form>
        </base-card>
      </v-dialog>
    </div>
  </v-row>
</template>
<script>
//local registration

import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { handleError } from "../../../constants/error";
import { GET_SINGLE_SALES, SEND_MAIL } from "../../../constants/graphql";
import VueHtml2pdf from "vue-html2pdf";
import store from "../../../store/index";

export default {
  //component code
  components: {
    FormWizard,
    TabContent,
    VueHtml2pdf,
  },
  data() {
    return {
      company: store.state.authData.company,
      loggedInUser: store.state.authData.userName,
      loading: false,
      loadingE: false,
      items: [],
      salesId: parseInt(this.$route.params.id),
      currency: store.state.authData.currency,
      resources: [],
      product: [],
      print: false,
      sale: [],
      getSale: "",
      valid: true,
      sendDialog: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      email: "",
      desserts: [
        {
          desc: "1 of Tiger Generator",
          amount: "35500.0",
        },
      ],
    };
  },

  apollo: {
    getSale: {
      query: GET_SINGLE_SALES,
      variables() {
        return {
          salesId: this.salesId,
        };
      },
      update: (result) => result.getSingleSales,
      error(error) {
        handleError(error);
      },
    },
  },
  methods: {
    printInvoice() {
      const prtHtml = document.getElementById("invoice").innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      const WinPrint = window.open("", "", "toolbar=0,scrollbars=0,status=0");

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
        </head>
        <body>
          ${prtHtml}
          <p style='text-align:center;'>Thanks for patronage</p>
        </body>

      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
      //this.$htmlToPaper("invoice");
      //this.$refs.html2Pdf.generatePdf();
    },
    sendMailDialog() {
      this.sendDialog = true;
    },
    sendInvoice() {
      this.loadingE = true;
      let validated = this.$refs.form.validate();
      if (validated) {
        this.$apollo
          .mutate({
            mutation: SEND_MAIL,
            variables: {
              recipientEmail: this.email,
              salesId: this.salesId,
            },
          })
          .then((res) => {
            this.loadingE = false;
            this.sendDialog = false;
            this.$swal.fire({
              toast: true,
              text: `Invoice has been sent to ${this.email} successfully`,
              icon: "success",
              timer: 5000,
              timerProgressBar: true,
              position: "top-end",
            });
          });
      }
    },
  },
};
</script>

<style>
.v-text-field input {
  text-align: center !important;
}
.my-font-class {
  font-family: "Century Gothic", monospace !important;
}
tr {
  color: #000 !important;
}

td {
  color: #000 !important;
}

th {
  color: #000 !important;
}
p {
  color: #000 !important;
}
</style>
